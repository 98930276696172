/* nav {
    background: #E1E1E1;
    position: absolute;
    width: 100%;
    height: 70px;
    color: black;
}

nav ul {
    display: flex;
    margin-left:15px ;
}
} */

nav{
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
    padding: 15px;
    color:#FDFDFD;
}
nav .logo_text{
    color: #FDFDFD;
    font-size: 25px;
    text-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25); 
}
nav ul li {
    font-size: 20px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #FDFDFD;
    /* margin-left: 20px; */
}



nav img {
    width: 50px;
    height: 50px;
   
    filter: drop-shadow(0px 4px 4px #005CEC);
    border-radius: 113px;
}

.navigator_forPhone {
    /* padding-top: 70px; */
    width: 0%;
    transition: width 2s;
    height: 50px;
    background: rgb(245, 245, 245);
    color: black;
    display: none;
    box-shadow: rgba(145, 145, 145, 0.1) 0px 4px 12px;
}

.navigator_forPhone .phonenavigator_tab {
    border-bottom: none;
    height: 110%;

}



.navigator_forPhone ul {
    width: 80%;
    /* background: #000; */
    display: flex;
    margin: auto;
    padding-left: 0px;
    justify-content: space-between;
    padding-top: 9px;
}

.navigator_forPhone ul li {
    font-size: 1.2rem;
    list-style: none;
    /* padding-left: 20px; */
   
}
.navigator_forPhone ul li a{
    text-decoration: none;
    color: black;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}


/* initial tab opens */
.navigator_forPhone .activetabFor_phone {
    border-bottom: 3px solid black;
}


/*  */











@media only screen and (max-width: 988px) {

    nav ul {
        display: none;
    }

    nav img{
        width: 60px;
        height: 65px;
        left: 20px;
        top: 20px;
    }
    .navigator_forPhone {
        display: block;
        width: 100%;
    }
    .togglericon{
        height: 14px;
        font-size: 40px;
        padding: 14px;
    
        /* border: none; */
        color: #FDFDFD;
    }
}